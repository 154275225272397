import { gsap, ScrollTrigger } from 'gsap/all'
import { useEffect, useRef, MutableRefObject } from 'react'
import { useIsLargeScreen } from 'src/helpers/layout'
import { createShowFromBottomAnimation } from 'src/helpers/animations'

gsap.registerPlugin(ScrollTrigger)

export const useTeaserWithIconsAnimations = (
  leftBox: MutableRefObject<HTMLDivElement>,
  rightBox: MutableRefObject<HTMLDivElement>
) => {
  const timeline = useRef(gsap.timeline())
  const isLarge = useIsLargeScreen()

  const animations = () => {
    createShowFromBottomAnimation(timeline.current, leftBox.current)
    createShowFromBottomAnimation(timeline.current, rightBox.current)
  }

  useEffect(() => {
    if (isLarge === null) {
      return
    }

    animations()
  }, [isLarge])
}

export default {}
