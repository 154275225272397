import React, { FC } from 'react'
import { directusFileUrl } from 'src/helpers/directus'

interface IAwardIconProps {
  className?: string
  icon: {
    icon: string
    title: string
  }
}

const AwardIcon: FC<IAwardIconProps> = ({ className, icon }) => (
  <div className={`${className} p-4 w-1/2 md:w-auto text-center`}>
    <img
      src={directusFileUrl(icon.icon)}
      alt={icon.title}
      className="w-28 inline-block"
    />
    <p className="text-center text-lg mt-2">{icon.title}</p>
  </div>
)

AwardIcon.defaultProps = {
  className: '',
}

export default AwardIcon
