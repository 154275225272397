import * as React from 'react'
import Layout from 'src/components/layout'
import { graphql } from 'gatsby'
import Hero from 'src/components/sections/hero'
import TeaserWithIcons from 'src/components/sections/teaser/teaser-with-icons/TeaserWithIcons'
import TeaserWithHighlight from 'src/components/sections/teaser/TeaserWithHighlight'
import OurProcess from 'src/components/sections/our-process'
import OurAwards from 'src/components/sections/our-awards'
import TrusedBy from 'src/components/sections/TrustedBy'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import ContactSection from 'src/components/sections/ContactSection'

const IndexPage = ({ data }) => {
  const translation = data.directus.pages[0].translations[0]
  if (!translation) {
    return <h1>Missing translation</h1>
  }

  const { t } = useTranslation('side-menu')

  const cmsJson = JSON.parse(translation.content)

  return (
    <Layout title={cmsJson.title} metas={cmsJson.metas}>
      <Hero translationContent={cmsJson.weAreBigThinkersSection} showBadges />
      <div data-name={t('divisions')} id={t('divisions')}>
        <TeaserWithIcons translationContent={cmsJson.imaginationSection} />
        <TeaserWithIcons
          translationContent={cmsJson.designAndDevelopmentSection}
        />
        <TeaserWithIcons translationContent={cmsJson.growthAndSupportSection} />
      </div>
      <TeaserWithHighlight
        isImageSnapped
        translationContent={cmsJson.sportSection}
      />
      <TeaserWithHighlight
        isReversed
        translationContent={cmsJson.securitySection}
      />
      <TeaserWithHighlight
        isImageSnapped
        translationContent={cmsJson.technologySection}
      />
      <OurProcess />
      <OurAwards />
      <TrusedBy />
      <ContactSection />
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    directus {
      pages(filter: { slug: { _eq: "/services" } }) {
        translations(filter: { languages_code: { code: { _eq: $language } } }) {
          content
        }
      }
    }
  }
`

export default IndexPage
