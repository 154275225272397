import { gsap, ScrollTrigger } from 'gsap/all'
import { useEffect, useRef } from 'react'
import { useIsLargeScreen } from 'src/helpers/layout'
import {
  getAnimationTriggerPoint,
  getAnimationDurationInSeconds,
} from 'src/helpers/animations'

gsap.registerPlugin(ScrollTrigger)

export const useIconsAnimation = () => {
  const timeline = useRef(gsap.timeline())
  const isLarge = useIsLargeScreen()

  const mobileAnimation = () => {
    gsap.set('#animated-line', {
      bottom: 'auto',
    })

    const iconBlocks = document.querySelectorAll('.icon-block')
    const iconImageWrappers = document.querySelectorAll('.icon-image-wrapper')

    ScrollTrigger.create({
      trigger: '#animated-line',
      start: getAnimationTriggerPoint(),
      onEnter: () => {
        timeline.current.to('#animated-line', {
          bottom: '4rem',
          ease: 'linear',
          duration: 5,
        })

        timeline.current.to(
          iconBlocks,
          {
            ease: 'linear',
            stagger: 1,
            duration: getAnimationDurationInSeconds(),
            color: '#101216',
          },
          0
        )

        timeline.current.to(
          iconImageWrappers,
          {
            ease: 'linear',
            stagger: 1,
            duration: getAnimationDurationInSeconds(),
            borderColor: '#6E7191',
          },
          0
        )
      },
    })

    ScrollTrigger.sort()
  }

  const desktopAnimations = () => {
    gsap.set('#animated-line', {
      right: 'auto',
    })

    const iconBlocks = document.querySelectorAll('.icon-block')
    const iconImageWrappers = document.querySelectorAll('.icon-image-wrapper')

    ScrollTrigger.create({
      trigger: '#animated-line',
      start: getAnimationTriggerPoint(),
      onEnter: () => {
        timeline.current.to('#animated-line', {
          right: '10rem',
          ease: 'linear',
          duration: 5,
        })

        timeline.current.to(
          iconBlocks,
          {
            ease: 'linear',
            stagger: 1,
            duration: getAnimationDurationInSeconds(),
            color: '#101216',
          },
          0
        )

        timeline.current.to(
          iconImageWrappers,
          {
            ease: 'linear',
            stagger: 1,
            duration: getAnimationDurationInSeconds(),
            borderColor: '#6E7191',
          },
          0
        )
      },
    })

    ScrollTrigger.sort()
  }

  useEffect(() => {
    if (isLarge === null) {
      return
    }

    if (isLarge) {
      desktopAnimations()
      return
    }

    mobileAnimation()
  })
}

export default {}
