// i18next-extract-mark-ns-start services

import React, { FC, useRef } from 'react'
import H2 from 'src/components/ui/title/H2'
import Subtitle from 'src/components/ui/Subtitle'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import OurProcessIcons from 'src/components/sections/our-process/Icons'
import { useOurProcessAnimations } from 'src/components/sections/our-process/animations'

interface IOurProcessProps {
  className?: string
}

const OurProcess: FC<IOurProcessProps> = ({ className }) => {
  const { t } = useTranslation('services')

  const subtitleRef = useRef<HTMLDivElement>(null)
  const largeTitleRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const iconsRef = useRef<HTMLDivElement>(null)

  useOurProcessAnimations(subtitleRef, largeTitleRef, contentRef, iconsRef)

  return (
    <section
      data-name={t('our process')}
      id={t('our process')}
      className={`${className} container py-10 md:py-20`}
    >
      <Subtitle ref={subtitleRef} className="opacity-0 mb-2 md:mb-6">
        {t('OUR PROCESS')}
      </Subtitle>
      <H2
        className="opacity-0 "
        ref={largeTitleRef}
        firstPart={t('we are fast')}
        secondPart={t('flexible & transparent')}
      />
      <p ref={contentRef} className="opacity-0 mt-6">
        {t('we adjust')}
      </p>
      <OurProcessIcons
        className="opacity-0"
        ref={iconsRef}
        icons={[
          {
            url: t('our process first icon image'),
            alt: t('workshops'),
            title: t('workshops'),
            description: t('We meet in person'),
          },
          {
            url: t('our process second icon image'),
            alt: t('product imagination'),
            title: t('product imagination'),
            description: t('Together we imagine'),
          },
          {
            url: t('our process third icon image'),
            alt: t('development and launch'),
            title: t('development and launch'),
            description: t('Everything you can'),
          },
          {
            url: t('our process fourth icon image'),
            alt: t('maintenance'),
            title: t('maintenance'),
            description: t('You can safely'),
          },
          {
            url: t('our process fifth icon image'),
            alt: t('enhancement'),
            title: t('enhancement'),
            description: t('We propose'),
          },
        ]}
      />
    </section>
  )
}

OurProcess.defaultProps = {
  className: '',
}

export default OurProcess
