// i18next-extract-mark-ns-start services

import React, { FC, useRef } from 'react'
import H2 from 'src/components/ui/title/H2'
import Subtitle from 'src/components/ui/Subtitle'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import AwardIcon from 'src/components/sections/our-awards/AwardIcon'
import { useOurAwardsAnimations } from 'src/components/sections/our-awards/animations'

interface IOurAwardsProps {
  className?: string
}

const OurAwards: FC<IOurAwardsProps> = ({ className }) => {
  const { t } = useTranslation('services')

  const subtitleRef = useRef<HTMLDivElement>(null)
  const largeTitleRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const iconsRef = useRef<HTMLDivElement>(null)

  useOurAwardsAnimations(subtitleRef, largeTitleRef, contentRef, iconsRef)

  const icons = [
    {
      icon: t('webstarIcon'),
      title: t('webstarTitle'),
    },
    {
      icon: t('mobileTrendsIcon'),
      title: t('mobileTrendsTitle'),
    },
    {
      icon: t('golderArrowIcon'),
      title: t('golderArrowTitle'),
    },
    {
      icon: t('KreaturaIcon'),
      title: t('KreaturaTitle'),
    },
    {
      icon: t('fwaIcon'),
      title: t('fwaTitle'),
    },
    {
      icon: t('effieIcon'),
      title: t('effieTitle'),
    },
  ]

  return (
    <section
      data-name={t('our awards')}
      id={t('our awards')}
      className={`${className} container py-10 md:py-20`}
    >
      <Subtitle ref={subtitleRef} className="mb-2 md:mb-6 opacity-0 text-right">
        {t('OUR AWARDS')}
      </Subtitle>
      <H2
        className="opacity-0 text-right"
        ref={largeTitleRef}
        firstPart={t('we are proud of')}
        secondPart={t('our achievements')}
      />
      <p ref={contentRef} className="mt-6 opacity-0 text-right">
        {t('We appreciate')}
      </p>
      <div
        ref={iconsRef}
        className="opacity-0 flex justify-between mt-12 flex-wrap md:flex-nowrap"
      >
        {icons.map((icon) => (
          <AwardIcon key={icon.title} icon={icon} />
        ))}
      </div>
    </section>
  )
}

OurAwards.defaultProps = {
  className: '',
}

export default OurAwards
