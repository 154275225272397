import React, { forwardRef } from 'react'
import { directusFileUrl } from 'src/helpers/directus'
import { useIconsAnimation } from 'src/components/sections/our-process/icons-animations'

interface ISingleIcon {
  url: string
  alt: string
  title: string
  description: string
}

interface IIconsProps {
  className?: string
  icons: ISingleIcon[]
}
const Icons = forwardRef<HTMLHeadingElement, IIconsProps>(
  ({ className, icons }, ref) => {
    useIconsAnimation()

    return (
      <div
        ref={ref}
        className={`${className} flex flex-wrap md:flex-nowrap mt-12 relative`}
      >
        <div
          id="animated-line"
          className="left-16 md:left-24 w-0.5 md:w-auto md:h-0.5 absolute md:right-24 top-4 md:top-12 bottom-4 md:bottom-auto bg-mediumDarkGray -z-10"
        />

        {icons.map((icon, index) => {
          const isLast = index + 1 === icons.length

          return (
            <div
              key={icon.title}
              className={`text-mediumDarkGray icon-block px-4 text-center w-full md:w-1/5 md:mb-0 flex md:block ${
                !isLast ? 'mb-24' : ''
              }`}
            >
              <div className="w-32 md:w-full flex items-center justify-center">
                <div className="icon-image-wrapper flex justify-center items-center w-24 h-24 border-2 border-mediumDarkGray rounded-md bg-white">
                  <img
                    src={directusFileUrl(icon.url)}
                    className="block"
                    alt={icon.alt}
                  />
                </div>
              </div>
              <div className="ml-8 md:ml-0">
                <p className="md:mt-6 text-left md:text-center text-lg">
                  {icon.title}
                </p>
                <p className="mt-2 md:mt-6 text-left md:text-center text-sm">
                  {icon.description}
                </p>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
)

Icons.defaultProps = {
  className: '',
}

export default Icons
