import React, { FC, useRef } from 'react'
import { Link } from 'gatsby-plugin-react-i18next'
import Subtitle from 'src/components/ui/Subtitle'
import Button from 'src/components/ui/Button'
import H2 from 'src/components/ui/title/H2'
import { useTeaserWithIconsAnimations } from 'src/components/sections/teaser/teaser-with-icons/animations'
import emphasizeAmpersand from 'src/helpers/emphasizeAmpersand'
import styled from 'styled-components'
import tw from 'twin.macro'

interface ISingleIcon {
  image: {
    url: string
    alt: string
  }
  title: string
}

export interface ITeaserWithIconsProps {
  translationContent: {
    firstPartOfTitle: string
    secondPartOfTitle: string
    subtitle: string
    content: string
    firstCtaLink: string
    secondCtaLink: string
    firstCtaText: string
    secondCtaText: string
    iconsHeader: string
    sectionName: string
    icons: ISingleIcon[]
  }
}

const StyledIconContainer = styled.div`
  &:nth-last-child(1),
  &:nth-last-child(2) {
    ${tw`mb-0`}
  }
`

const getMappedIconsToComponents = (icons: ISingleIcon[]) =>
  icons.map(({ image, title }) => (
    <StyledIconContainer key={title} className="w-1/2 mb-16">
      <img
        src={image.url}
        alt={image.alt}
        className="mx-auto"
        style={{ maxWidth: '120px' }}
      />
      <h3 className="font-palanquinDark font-semibold text-lg text-center mt-4 whitespace-pre">
        {emphasizeAmpersand(title, 1.1)}
      </h3>
    </StyledIconContainer>
  ))

const TeaserWithIcons: FC<ITeaserWithIconsProps> = ({
  translationContent: {
    firstPartOfTitle,
    secondPartOfTitle,
    subtitle,
    content,
    firstCtaLink,
    secondCtaLink,
    firstCtaText,
    secondCtaText,
    iconsHeader,
    icons,
    sectionName,
  },
}) => {
  const leftBoxRef = useRef<HTMLDivElement>(null)
  const rightBoxRef = useRef<HTMLDivElement>(null)

  useTeaserWithIconsAnimations(leftBoxRef, rightBoxRef)

  return (
    <section
      data-name={sectionName}
      id={sectionName}
      className="py-10 md:py-20 flex flex-wrap lg:flex-nowrap container"
    >
      <div ref={leftBoxRef} className="w-full lg:w-1/2 opacity-0">
        <Subtitle className="mb-2 md:mb-4">{subtitle}</Subtitle>
        <H2
          firstPart={firstPartOfTitle}
          secondPart={secondPartOfTitle}
          secondPartRed={false}
        />
        <div className="mt-8">
          <p className="md:pr-12 xl:pr-48">{content}</p>
        </div>
        <div className="mt-16 flex flex-row justify-center md:justify-start md:flex-row flex-wrap">
          <Link to={firstCtaLink}>
            <Button>{firstCtaText}</Button>
          </Link>
          <Link to={secondCtaLink} className="ml-6 mt-0 inline-block">
            <Button isOutline>{secondCtaText}</Button>
          </Link>
        </div>
      </div>
      <div
        ref={rightBoxRef}
        className="w-full lg:w-1/2 mt-24 lg:mt-0 opacity-0"
      >
        <h4 className="text-mediumDarkGray font-bold">{iconsHeader}</h4>
        <div className="flex flex-wrap mt-12">
          {getMappedIconsToComponents(icons)}
        </div>
      </div>
    </section>
  )
}

export default TeaserWithIcons
